type Props = {
    color? :string;
    radius? :string;
};

export default function Logo(props :Props){
    return(
        <div style={{
            width: props.radius,
            height: props.radius
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100">
                <path fill={props.color} d="M9 16h5c4 0 6-2 6-6h4v1c0 5-4 9-9 9H9a1 1 0 010-4zm0-36h6c5 0 9 4 9 9v1h-4c0-4-2-6-6-6H9a1 1 0 010-4M21 4l3-3c1-1 1-1 0-2l-3-3a1 1 0 013-3l6 6c1 1 1 1 0 2l-6 6a1 1 0 01-3-3M9-2h9a1 1 0 010 4H9a1 1 0 010-4M-6 14l3 3c1 1 1 1 0 2l-3 3a1 1 0 003 3l6-6c1-1 1-1 0-2l-6-6a1 1 0 00-3 3m0-18a1 1 0 013-3l6 6c1 1 1 1 0 2l-6 6a1 1 0 01-3-3l3-3c1-1 1-1 0-2l-3-3m0-10a1 1 0 003 3l6-6c1-1 1-1 0-2l-6-6a1 1 0 00-3 3l3 3c1 1 1 1 0 2l-3 3M-30 5a1 1 0 014 0v9c0 1 1 2 2 2h15a1 1 0 010 4h-17c-3 0-4-1-4-4V5m0-10a1 1 0 004 0v-9c0-1 1-2 2-2h15a1 1 0 000-4h-17c-3 0-4 1-4 4v11m9 3a1 1 0 000 4h12a1 1 0 000-4h-12Z"/>
            </svg>
        </div>
    );
}