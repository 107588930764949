import { Component as Cp, ReactNode, useState } from "react";
import exampleData from "../assets/exampleData";
import Mermaid from "./Mermaid/Mermaid";
import NavBar from "./NavBar/NavBar";
import Chat from "./Chat/Chat";
import meta from "../assets/meta";
import mermaid from "mermaid";

type State = {
    data :string;
    chatOpened :boolean;
};

/**@once*/
export default class App extends Cp<{}, State>{
    constructor(props :{}){
        super(props);
        this.state = {
            data: exampleData.basic as string,
            chatOpened: false
        };
    }
    componentDidMount(){
        mermaid.initialize({
            fontFamily: `ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            htmlLabels: false
        });
    }
    render(){
        return(<>
            <NavBar updateData={(data :string)=>this.setState({data})} toggleChat={()=>this.setState({chatOpened: !this.state.chatOpened})} />
            <div style={{
                display: "flex",
                flexFlow: "row nowrap",
                height: `calc(100dvh - ${meta.navBarHeight})`,
                overflowY: "auto"
            }}>
                <Mermaid chatOpened={this.state.chatOpened} id="mermaid-main" text={this.state.data} width={this.state.chatOpened ? "50dvw" : "100dvw"} height={`calc(100dvh - ${meta.navBarHeight})`} />
                {this.state.chatOpened ? <Chat /> : null}
            </div>
        </>);
    }
}