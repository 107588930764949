import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./css/main.css";
import "./css/main.scrollbar.css";
import "./css/main.basicElements.css";
import "./css/main.fixes.css";
import App from "./components/App";
import { mountGetPx } from "./utils";

export function load(){
    createRoot(document.getElementById("root")!).render(<StrictMode><App /></StrictMode>);
}
load();

mountGetPx();