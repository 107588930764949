import { Component as Cp, createRef } from "react";
import mainStyles from "../../css/main.module.css";
import styles from "./Mermaid.module.css";
import mermaid from "mermaid";
import { Button } from "@headlessui/react";

type Props = {
    id :string;
    text :string;
    width :string;
    height :string;
    chatOpened :boolean;
};

type State = {
    scale :number;
    X :number;
    Y :number;
    svg :string;
    moving :boolean;
    deltaX :number;
    deltaY :number;
    scaleTip :{
        showed :boolean;
        top :number;
        left :number;
    };
    contextMenu :{
        showed :boolean;
        pressedDown :boolean;
        top :number;
        left :number;
    };
    boundaries :{
        maxX :number;
        maxY :number;
        minX :number;
        minY :number;
    };
};

export default class Mermaid extends Cp<Props, State>{
    static maxScale = 20;
    static minScale = 0.2;
    static scaleSensitivity = 0.11;
    static scaleMoveSensitivity = 0.9;
    static transformSensitivity = 1;
    static boundaryInset = 16;
    static tipHideTimeOut = 1500;

    outerRef = createRef<HTMLDivElement>();
    innerRef = createRef<HTMLDivElement>();
    contextMenuRef = createRef<HTMLDivElement>();
    tipRef = createRef<HTMLDivElement>();
    tipId = -1;

    constructor(props :Props){
        super(props);
        this.state = {
            scale: 1, X: 0, Y: 0, svg: "", moving: false, deltaX: 0, deltaY: 0,
            scaleTip: {showed: false, top: -12914, left: -12914},
            contextMenu: {showed: false, pressedDown: false, top: 0, left: 0},
            boundaries: {maxX: 0, maxY: 0, minX: 0, minY: 0}
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>){
        for(let i in this.props) if(this.props[i as keyof Props] !== prevProps[i as keyof Props]){
            this.setState({scaleTip: {showed: false, top: -12914, left: -12914}});
            this.renderSVG();
        }
    }

    componentDidMount(){
        this.outerRef.current!.addEventListener("wheel", this.wheel);
        this.outerRef.current!.addEventListener("pointerdown", this.pointerDown);
        document.addEventListener("pointermove", this.pointerMove);
        document.addEventListener("pointerup", this.globalPointerUp);
        this.outerRef.current!.addEventListener("pointerup", this.pointerUp);
        this.renderSVG();
    }

    renderSVG = ()=>{
        setTimeout(async ()=>{
            const {svg, bindFunctions} = await mermaid.render(this.props.id, this.props.chatOpened ? this.props.text.startsWith("flowchart LR") ? this.props.text.replace("flowchart LR", "flowchart TD") : this.props.text : this.props.text.startsWith("flowchart TD") ? this.props.text.replace("flowchart TD", "flowchart LR") : this.props.text);
            this.setState({svg}, ()=>{
                const {X, Y} = this.getCenterXY();
                this.setState({X, Y, boundaries: this.getBoundaries()});
            });
            bindFunctions?.(this.innerRef.current!);
        }, 0);
    }

    componentWillUnmount(){
        this.outerRef.current!.removeEventListener("wheel", this.wheel);
        this.outerRef.current!.removeEventListener("pointerdown", this.pointerDown);
        document.removeEventListener("pointermove", this.pointerMove);
        document.removeEventListener("pointerup", this.globalPointerUp);
        this.outerRef.current!.removeEventListener("pointerup", this.pointerUp);
    }

    getCenterXY = (ignoreScale? :boolean)=>{
        const outerRect = this.outerRef.current!.getBoundingClientRect(), innerRect = this.innerRef.current!.getBoundingClientRect();
        return ignoreScale ? {
            X: (outerRect.width - (innerRect.width / this.state.scale)) / 2,
            Y: (outerRect.height - (innerRect.height / this.state.scale)) / 2,
        } : {
            X: (outerRect.width - innerRect.width) / 2,
            Y: (outerRect.height - innerRect.height) / 2,
        };
    }

    getBoundaries = ()=>{
        const
            outerRect = this.outerRef.current!.getBoundingClientRect(),
            innerRect = this.innerRef.current!.getBoundingClientRect(),
            originX = innerRect.width * (1 - 1 / this.state.scale) / 2,
            originY = innerRect.height * (1 - 1 / this.state.scale) / 2,
            newInset = Mermaid.boundaryInset * this.state.scale;
        return{
            maxX: originX + outerRect.width - newInset,
            maxY: originY + outerRect.height - newInset,
            minX: originX + newInset - innerRect.width,
            minY: originY + newInset - innerRect.height
        };
    }

    wheel = (event :WheelEvent)=>{
        const
            //1：向上；zoom-in；-1：向下；zoom-out
            rawScale = this.state.scale * Math.exp((event.deltaY > 0 ? -1 : 1) * Mermaid.scaleSensitivity),
            scale = event.deltaY > 0 ? rawScale >= Mermaid.minScale ? rawScale : Mermaid.minScale : rawScale <= Mermaid.maxScale ? rawScale : Mermaid.maxScale,
            {top, left, height, width} = this.innerRef.current!.getBoundingClientRect(),
            newX = this.state.X + (event.pageX - (left + width / 2)) * (1 - scale / this.state.scale),
            newY = this.state.Y + (event.pageY - (top + height / 2)) * (1 - scale / this.state.scale);
        this.setState({scale}, ()=>{
            const boundaries = this.getBoundaries();
            this.setState({
                boundaries,
                scaleTip: {
                    ...this.state.scaleTip,
                    showed: true
                }
            }, ()=>{
                const tipRect = this.tipRef.current!.getBoundingClientRect(), outerRect = this.outerRef.current!.getBoundingClientRect();
                this.setState({scaleTip: {
                    ...this.state.scaleTip,
                    top: outerRect.top + (outerRect.height - tipRect.height) / 2,
                    left: outerRect.left + (outerRect.width - tipRect.width) / 2,
                }});
                if(this.tipId !== -1) clearTimeout(this.tipId);
                this.tipId = setTimeout(()=>{
                    this.tipId = -1;
                    this.setState({scaleTip: {
                        showed: false,
                        top: -12914,
                        left: -12914
                    }});
                }, Mermaid.tipHideTimeOut) as unknown as number;
            });
            if(newX > this.state.boundaries.maxX) this.setState({X: boundaries.maxX});
            else if(newX < this.state.boundaries.minX) this.setState({X: boundaries.minX});
            else this.setState({X: newX});
            if(newY > this.state.boundaries.maxY) this.setState({Y: boundaries.maxY});
            else if(newY < this.state.boundaries.minY) this.setState({Y: boundaries.minY});
            else this.setState({Y: newY});
        });
    }

    pointerDown = (event :PointerEvent)=>{
        const {tagName} = event.target as Element;
        this.setState({contextMenu: {
            ...this.state.contextMenu,
            pressedDown: true
        }});
        if((tagName === "DIV" || tagName === "svg" || tagName === "g") && (event.button === 0) && !this.state.moving) this.setState({
            moving: true,
            deltaX: this.state.X - event.pageX,
            deltaY: this.state.Y - event.pageY
        });
    }

    pointerMove = (event :PointerEvent)=>{
        //console.log(event.pageY);
        if(this.state.moving){
            if(event.buttons === 0) this.setState({moving: false});
            else{
                const
                    newX = this.state.deltaX + event.pageX,
                    newY = this.state.deltaY + event.pageY,
                    {boundaries} = this.state;
                if(newX > this.state.boundaries.maxX) this.setState({X: boundaries.maxX});
                else if(newX < this.state.boundaries.minX) this.setState({X: boundaries.minX});
                else this.setState({X: newX});
                if(newY > this.state.boundaries.maxY) this.setState({Y: boundaries.maxY});
                else if(newY < this.state.boundaries.minY) this.setState({Y: boundaries.minY});
                else this.setState({Y: newY});
            }
        }
    }

    pointerUp = (event :PointerEvent)=>{
        if(this.state.moving) this.setState({moving: false, deltaX: 0, deltaY: 0});
        if(this.state.contextMenu.pressedDown && this.state.contextMenu.showed) this.setState({contextMenu: {
            ...this.state.contextMenu,
            showed: false,
            pressedDown: false
        }});
    }

    globalPointerUp = (event :PointerEvent)=>{
        this.setState({contextMenu: {
            ...this.state.contextMenu,
            pressedDown: false
        }});
    }

    contextMenu = (event :React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        const {tagName} = event.target as Element;
        if(tagName === "DIV" || tagName === "svg" || tagName === "g"){
            event.preventDefault();
            this.setState({contextMenu: {
                showed: true,
                pressedDown: false,
                top: event.pageY,
                left: event.pageX
            }}, ()=>{
                const
                    {top, left, height, width} = this.contextMenuRef.current!.getBoundingClientRect(),
                    {top: oTop, left: oLeft, height: oHeight, width: oWidth} = this.outerRef.current!.getBoundingClientRect();
                if(top + height > oTop + oHeight || left + width > oLeft + oWidth) this.setState({contextMenu: {
                    ...this.state.contextMenu,
                    top: event.pageY - height,
                    left: event.pageX - width
                }});
            });
        }
    }

    center = (resetScale :boolean)=>{
        const {X, Y} = this.getCenterXY(true);
        this.setState({
            X, Y, boundaries: this.getBoundaries(),
            scale: resetScale ? 1 : this.state.scale,
            contextMenu: {
                ...this.state.contextMenu,
                showed: false
            }
        });
    }

    render(){
        return(<>
            <div ref={this.outerRef} className={`${styles.outer}${this.state.moving ? ` ${mainStyles.noselect}` : ""}`} onContextMenu={this.contextMenu} style={{
                width: this.props.width,
                height: this.props.height,
                overflow: "clip",
                cursor: this.state.moving ? "grabbing" : "grab"
            }}>
                <div ref={this.tipRef} className={`${mainStyles.noselect} ${styles.scaleTip}`} style={{
                    display: this.state.scaleTip.showed ? "block" : "none",
                    top: this.state.scaleTip.top,
                    left: this.state.scaleTip.left
                }}>{(this.state.scale * 100).toFixed(0)}%</div>
                <div ref={this.innerRef} className={styles.inner} dangerouslySetInnerHTML={{__html: this.state.svg}} style={{
                    transform: `translate(${this.state.X}px, ${this.state.Y}px) scale(${this.state.scale})`,
                    //outline: "solid 1px black"
                }} />
            </div>
            <div ref={this.contextMenuRef} className={`${mainStyles.noselect} ${styles.contextMenu}`} style={{
                display: this.state.contextMenu.showed ? "block" : "none",
                top: this.state.contextMenu.top,
                left: this.state.contextMenu.left
            }}><div className={styles.contextMenuInner}>
                <Button onClick={()=>this.center(false)}>回到中心</Button>
                <Button onClick={()=>this.center(true)}>重置视图</Button>
            </div></div>
        </>);
    }
}