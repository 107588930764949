import { Button, CloseButton, Dialog, DialogPanel, Textarea } from "@headlessui/react";
import mainStyles from "../../css/main.module.css";
import styles from "./NavBar.module.css";
import Logo from "../../assets/logo.svg";
import { useRef, useState } from "react";
import meta from "../../assets/meta";

type Props = {
    updateData :(data :string)=>void;
    toggleChat :()=>void;
};

/**@once*/
export default function NavBar(props :Props){
    const
        [isOpenOpen, setIsOpenOpen] = useState(false),
        code = useRef<HTMLTextAreaElement>(null),
        file = useRef<HTMLInputElement>(null),
        input = ()=>{
            if(code.current!.value !== "") props.updateData(code.current!.value);
            else if(file.current!.files![0]){
                //console.log(file.current!.files![0]);
                const reader = new FileReader();
                reader.readAsText(file.current!.files![0]);
                reader.addEventListener("load", data=>props.updateData(data.target!.result! as string));
            }
        };
    return(<>
        <div className={styles.wrapper} style={{height: `calc(${meta.navBarHeight} - 2px)`}}>
            <Logo color="#5C6BE0" radius="5rem" />
            <div className={`${mainStyles.noselect} ${styles.title}`}>简形</div>
            <div className={styles.marginProvider}><Button style={{backgroundColor: "transparent", height: meta.navBarHeight}}><div className={`${mainStyles.noselect} ${styles.open}`} style={{height: meta.navBarHeight}} onClick={()=>setIsOpenOpen(true)}>加载流程图</div></Button></div>
            <div className={styles.marginProvider}><Button style={{backgroundColor: "transparent", height: meta.navBarHeight}}><div className={`${mainStyles.noselect} ${styles.open}`} style={{height: meta.navBarHeight}} onClick={props.toggleChat}>聊天</div></Button></div>
            <Dialog open={isOpenOpen} onClose={()=>setIsOpenOpen(false)} className={`${mainStyles.fullScreen} ${styles.dialogWrapper}`}>
                <DialogPanel>
                    <div className={styles.dialog}>
                        <div>粘贴 Mermaid 代码：</div>
                        <Textarea ref={code} className={styles.code} />
                        <hr />
                        <div>或选择文件：</div>
                        <input ref={file} type="file" />
                        <CloseButton className={styles.submit} onClick={input}><div>确定</div></CloseButton>
                    </div>
                </DialogPanel>
            </Dialog>
        </div>
    </>);
}